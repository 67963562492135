<template>
    <div id="app">
        <router-view :key="$route.fullPath"/>
    </div>
</template>

<script>
import ls from 'local-storage';
export default {
    name: "App",
    computed:{
        userInfo(){
            return this.$store.state.userInfo || ls.get("userInfo");
        }
    },
    created(){
        const _sign = ls.get('token');
        if( _sign && !this.userInfo ){
            this.$router.push({path:'/login'});
        }
    }
};
</script>

<style lang="scss">
@import "@/assets/css/common.scss"
</style>
