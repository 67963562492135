import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import ls from 'local-storage';
import {MessageBox} from 'element-ui';
import modules from './modules'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: () => import('@/views/passport/login'),
    meta: {
        requireAuth: false
    }
}, {
    path: '/',
    component: () => import('@/layout'),
    redirect: '/index',
    meta: {
        requireAuth: true
    },
    children: [
        {
            path: '404',
            name: '404',
            component: () => import('@/views/error/404'),
            meta: {
                breadcrumb: false
            }
        },
        {
            path: 'index',
            name: 'index',
            component: () => import('@/views/index'),
            meta: {
                breadcrumb: false
            }
        },
        ...modules,
    ]
},
{path: '*', redirect: '/404', meta: {breadcrumb: false}}]

//使用push()、replace()进行导航时，不能重复导航到当前路由。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({x: 0, y: 0}),
    routes
})

//登录拦截
router.beforeEach((to, from, next) => {
    if (to.matched.some(r => r.meta.requireAuth)) {
        if (to.path === '/login') return next()
        const _sign = ls.get('userInfo');
        if (_sign) {
            if( !store.state.userInfo){
                // 获取用户信息
                store.dispatch('getUser');
            }
            next();
            
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        next()
    }
})

//异常处理
router.onError(async error => {
    if (error.toString().indexOf('ChunkLoadError') === -1) {
        return;
    }

    const flag = await MessageBox.confirm('服务器版本已经更新，页面刷新后可以恢复正常使用', '提示', {
        confirmButtonText: '立即刷新',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
        closeOnPressEscape:false,
        center: true
    }).catch(() => 'cancel');

    if (flag === 'cancel') {
        return;
    }

    window.location.reload();
})

export default router