import axios from 'axios';
import store from '@/store';
import router from '@/router';
// import qs from 'qs'
// import ls from 'local-storage';
const {NODE_ENV} = process.env;

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || '', // api base_url
    timeout: 10 * 60 * 1000, // request timeout
    method: "POST"
});

// request interceptor
service.interceptors.request.use(
    config => {
        // api formatt
        // if (!config.url.endsWith('.json')) {
        //     config.url += '.json';
        // }
        // if(config.data && config.formData != false){
        //     config.data = qs.stringify( config.data )
        // }
        config.headers = {
            ...config.headers
        }
        if (NODE_ENV === 'development') {
            config.headers.xchost = 'localhost'
        }
        return config;
    },
    error => {
        // Do something with request error
        Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    res => {
        const { errmsg, errcode } = res.data;
        const responseData = {
            state: errcode == 1 ? 1 : 0,
            msg: errmsg,
        }
        res.data = {...res.data,...responseData}    
        //Vue.set(res.data,'state',res.data.code == 200 ? true : false);
        
        // Stream file
        if (res.request.responseType === 'blob' && res.data instanceof Blob) {
            return res;
        }

        // Not login
        const failState = ['user.login','not.login'];
        const  path =  router.currentRoute.path;
        const  fullPath =  router.currentRoute.fullPath;
        if (failState.includes(res.data.errcode)) {
            store.commit('logOut');
            if(path !== '/login'){
                router.push({
                    path: '/login',
                    query: { redirect: fullPath }
                })
            }
        }
        // console.log(res);
        return res;
    },
    error => {
        return Promise.reject(error);
    }
);

export default service;
