// 系统管理路由
const system =  {
    path: 'system',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '系统管理'},
    children: [
        {
            path: 'user',
            component: () => import('@/components/RouterView'),
            redirect: '/system/user/list',
            meta: { title: '用户管理'},
            children: [{
                path: 'list',
                component: () => import('@/views/system/user/list'),
                meta: {title: '用户列表'}
            }]
        },
    ]
}

export default system