// 商品管理路由
const goods =  {
    path: 'goods',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '商品管理'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/goods/list'),
            meta: {title: '商品列表'}
        },
        {
            path: 'add',
            component: () => import('@/views/goods/edit'),
            meta: {title: '新增商品'}
        },
        {
            path: 'edit/:id',
            component: () => import('@/views/goods/edit'),
            meta: {title: '编辑商品'},
            props: true
        },
    ]
}

export default goods