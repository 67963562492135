// 客户管理路由
const customer =  {
    path: 'customer',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '客户管理'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/customer/list'),
            meta: {title: '客户列表'}
        },
        {
            path: 'add',
            component: () => import('@/views/customer/edit'),
            meta: {title: '新增客户'}
        },
        {
            path: 'edit/:id',
            component: () => import('@/views/customer/edit'),
            meta: {title: '编辑客户'},
            props: true
        },
    ]
}

export default customer