// 点灯记录路由管理
const light =  {
    path: 'light',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '点灯记录'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/light/list'),
            meta: {title: '点灯记录列表'}
        },
    ]
}

export default light