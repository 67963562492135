// 商家管理路由
const biz =  {
    path: 'biz',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '商家管理'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/biz/list'),
            meta: {title: '商家列表'}
        },
        {
            path: 'add',
            component: () => import('@/views/biz/edit'),
            meta: {title: '新增商家'}
        },
        {
            path: 'edit/:id',
            component: () => import('@/views/biz/edit'),
            meta: {title: '编辑商家'},
            props: true
        },
    ]
}

export default biz