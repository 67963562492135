// 礼金管理路由管理
const lijin =  {
    path: 'lijin',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '礼金管理'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/lijin/list'),
            meta: {title: '礼金记录列表'}
        },
    ]
}

export default lijin