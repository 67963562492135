import Vue from 'vue'
import Vuex from 'vuex'
import ls from 'local-storage'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // token: null, //登录用户签名
    userInfo: null, //登录用户信息
    menuList: null, //权限、菜单
    baseConfig: null,//基础配置信息
  },
  mutations: {
    // setToken(state,data){
    //   state.token = data;
    //   ls.set('token',data);
    // },
    setUserInfo(state,data){
      state.userInfo = data;
      ls.set('userInfo',data);
    },
    setMenuList(state, data) {
      state.menuList = data;
      ls.set('menuList',data);
    },
    logOut(state){
      state.token = null;
      // ls.remove('token');
      state.menuList = null;
      ls.remove('menuList');
      state.userInfo = null;
      ls.remove('userInfo');
    },
  },
  actions: {
    getUser ({ commit, state }) {
      let userInfo = ls.get('userInfo')
      // let token = ls.get('token')
      let menuList = ls.get('menuList')
      // user = JSON.parse(unescape(user))
      // commit('setToken', token)
      commit('setUserInfo', userInfo)
      commit('setMenuList', menuList)
    }
  },
  modules: {
  }
})
