// 讣告管理路由
const fugao =  {
    path: 'fugao',
    component: () => import('@/components/RouterView'),
    redirect: 'noRedirect',
    meta: {title: '讣告管理'},
    children: [
        {
            path: 'list',
            component: () => import('@/views/fugao/list'),
            meta: {title: '讣告列表'}
        },
        {
            path: 'add',
            component: () => import('@/views/fugao/edit'),
            meta: {title: '新增讣告'}
        },
        {
            path: 'edit',
            component: () => import('@/views/fugao/edit'),
            meta: {title: '编辑讣告'},
        },
        {
            path: 'review/:id',
            component: () => import('@/views/fugao/review/list'),
            meta: {title: '讣告评论'},
            props: true
        },
    ]
}

export default fugao